<template>
  <div class="container">
    <div class="row">
      <div class="col-xl-4">
        <h2>{{ $t("analytics.trending_searches.title") }}</h2>
        <span class="text-muted mt-3 font-weight-bold font-size-sm">
          {{ userConf.date_range.startDate | moment }} - {{ userConf.date_range.endDate | moment }}
        </span>
      </div>
      <div class="col-xl-8 ">
        <UsStatsConfiguration></UsStatsConfiguration>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-8">
        <div class="card card-custom gutter-b">
          <div class="card-header h-auto border-0">
            <div class="card-title">
              <h3 class="card-label">
                {{ $t("analytics.trending_searches.list.timeline.title") }}
                <span class="d-block text-muted pt-2 font-size-sm">{{
                  $t("analytics.trending_searches.list.timeline.subtitle")
                }}</span>
              </h3>
            </div>
            <div class="card-toolbar">
              <span class="text-primary font-weight-bolder font-size-h3"
                ><i
                  class="fa fa-search text-primary font-weight-bolder font-size-h3 mr-2"
                ></i>
                {{
                  parseInt(
                    trending_searches.totals && trending_searches.totals.queries
                      ? trending_searches.totals.queries
                      : 0
                  ).toLocaleString()
                }}
                &nbsp;/&nbsp;<i
                  class="fa fa-users text-primary font-weight-bolder font-size-h3 mr-2"
                ></i>
                {{
                  parseInt(
                    trending_searches && trending_searches.unique_users
                      ? trending_searches.unique_users
                      : 0
                  ).toLocaleString()
                }}</span
              >
            </div>
          </div>
          <!--begin::Body-->
          <div class="card-body p-0">
            <div v-if="this.loading">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                style="margin:auto;background:#fff;display:block;"
                width="200px"
                height="200px"
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid"
              >
                <g transform="translate(20 50)">
                  <circle cx="0" cy="0" r="6" fill="#7b0000">
                    <animateTransform
                      attributeName="transform"
                      type="scale"
                      begin="-0.375s"
                      calcMode="spline"
                      keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                      values="0;1;0"
                      keyTimes="0;0.5;1"
                      dur="1s"
                      repeatCount="indefinite"
                    ></animateTransform>
                  </circle>
                </g>
                <g transform="translate(40 50)">
                  <circle cx="0" cy="0" r="6" fill="#c34c37">
                    <animateTransform
                      attributeName="transform"
                      type="scale"
                      begin="-0.25s"
                      calcMode="spline"
                      keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                      values="0;1;0"
                      keyTimes="0;0.5;1"
                      dur="1s"
                      repeatCount="indefinite"
                    ></animateTransform>
                  </circle>
                </g>
                <g transform="translate(60 50)">
                  <circle cx="0" cy="0" r="6" fill="#fd7f64">
                    <animateTransform
                      attributeName="transform"
                      type="scale"
                      begin="-0.125s"
                      calcMode="spline"
                      keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                      values="0;1;0"
                      keyTimes="0;0.5;1"
                      dur="1s"
                      repeatCount="indefinite"
                    ></animateTransform>
                  </circle>
                </g>
                <g transform="translate(80 50)">
                  <circle cx="0" cy="0" r="6" fill="#ffbea0">
                    <animateTransform
                      attributeName="transform"
                      type="scale"
                      begin="0s"
                      calcMode="spline"
                      keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                      values="0;1;0"
                      keyTimes="0;0.5;1"
                      dur="1s"
                      repeatCount="indefinite"
                    ></animateTransform>
                  </circle>
                </g>
              </svg>
            </div>
            <div
              v-else-if="
                graphData && graphData[0].data && graphData[0].data.length > 0
              "
            >
              <!--begin::Chart-->
              <apexchart
                class="card-rounded-bottom"
                :options="chartOptions"
                :series="graphData"
                type="area"
                height="180"
                width="100%"
              ></apexchart>
            </div>
            <div v-else>
              <div
                class="d-flex align-items-center justify-content-between card-spacer flex-grow-1"
              >
                <div class="card-body text-center">
                  <div class="row">
                    <div class="col-sm-12">
                      <h2>{{ $t("analytics.trending_searches.no_result") }}</h2>
                      <p>
                        {{
                          $t("analytics.trending_searches.no_result_subtitle")
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--end::Chart-->
          </div>
          <!--end::Body-->
        </div>
      </div>
      <div class="col-xl-4">
        <div class="card card-custom gutter-b">
          <!--begin::Body-->
          <div class="card-body">
            <!--begin::Content-->
            <div class="d-flex flex-wrap">
              <!--begin::Progress-->
              <div class="flex-row-fluid">
                <h2 class="text-primary" v-if="loading">
                  Suma unikátních lidí, kteří hledali
                </h2>
                <h2 class="text-primary" v-else>
                  {{
                    parseInt(
                      trending_searches.totals &&
                        trending_searches.totals.unique_queries
                        ? trending_searches.totals.unique_queries
                        : 0
                    ).toLocaleString()
                  }}
                </h2>
                <span class="d-block font-weight-bold mb-4">{{
                  $t("analytics.trending_searches.list.timeline.unique_queries")
                }}</span>
              </div>

              <div class="d-flex flex-column">
                <span class="svg-icon svg-icon-primary svg-icon-5x">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <polygon points="0 0 24 0 24 24 0 24" />
                      <path
                        d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,
        9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,
        4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                        opacity="0.3"
                      />
                      <path
                        d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,
        20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,
        15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,
        13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,
        21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                      />
                    </g></svg
                  ><!--end::Svg Icon--></span
                >
              </div>
              <!--end::Progress-->
            </div>
            <!--end::Content-->
          </div>
          <!--end::Footer-->
        </div>
        <div class="card card-custom gutter-b">
          <!--begin::Body-->
          <div class="card-body">
            <!--begin::Content-->
            <div class="d-flex flex-wrap">
              <!--begin::Progress-->
              <div class="flex-row-fluid">
                <h2 class="text-primary" v-if="loading">
                  -
                </h2>
                <h2 class="text-primary" v-else>
                  {{
                    parseInt(trending_searches.unique_queries).toLocaleString()
                  }}
                </h2>
                <span class="d-block font-weight-bold mb-4">{{
                  $t("analytics.trending_searches.list.timeline.search_terms")
                }}</span>
              </div>

              <div class="d-flex flex-column">
                <span class="svg-icon svg-icon-primary svg-icon-5x"
                  ><!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-05-14-112058/theme/html/demo1/dist/../src/media/svg/icons/General/Search.svg--><svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24" />
                      <path
                        d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,
                        15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,
                        19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,
                        21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                        opacity="0.3"
                      />
                      <path
                        d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,
                        6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,
                        11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,
                        18 11,18 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                      />
                    </g></svg
                  ><!--end::Svg Icon--></span
                >
              </div>
              <!--end::Progress-->
            </div>
            <!--end::Content-->
          </div>
          <!--end::Footer-->
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-12">
        <div class="card card-custom gutter-b">
          <div class="card-header flex-wrap py-3">
            <div class="card-title">
              <h3 class="card-label">
                {{ $t("analytics.trending_searches.list.table.title") }}
              </h3>
            </div>
            <div class="card-toolbar"></div>
          </div>
          <div class="card-body">
            <div
              id="kt_datatable_wrapper"
              class="dataTables_wrapper dt-bootstrap4 no-footer"
            >
              <div class="row">
                <div class="col-sm-12">
                  <v-data-table
                    dense
                    :headers="headers"
                    :items="trending_searches.table"
                    :options.sync="options"
                    :loading="loading"
                    :loading-text="
                      $t('analytics.trending_searches.list.table.loading')
                    "
                    :search="search"
                    class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                  >
                    <template v-slot:item.term="{ item }">
                      <router-link
                        :to="{
                          name: 'trending-searches-item',
                          query: { term: item.term }
                        }"
                      >
                        {{ item.term }}
                      </router-link>
                    </template>
                    <template v-slot:item.result_setting_type_id="{ item }">
                      {{
                        item.result_setting_type_id == 1
                          ? "Autocomplete"
                          : "Search"
                      }}
                    </template>
                    <template v-slot:item.queries="{ item }">
                      {{ parseInt(item.queries).toLocaleString() }}
                    </template>
                    <template v-slot:item.unique_queries="{ item }">
                      {{ parseInt(item.unique_queries).toLocaleString() }}
                    </template>
                    <template v-slot:item.results_count="{ item }">
                      {{ parseInt(item.results_count).toLocaleString() }}
                    </template>
                    <template v-slot:item.phrases_count="{ item }">
                      {{ parseInt(item.phrases_count).toLocaleString() }}
                    </template>
                    <template v-slot:item.fixits="{ item }">
                      {{ parseInt(item.fixits).toLocaleString() }}
                    </template>
                    <template v-slot:top>
                      <div
                        class="d-flex align-items-center position-relative my-1"
                      >
                        <span
                          class="svg-icon svg-icon-1 position-absolute ms-6"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <rect
                              opacity="0.5"
                              x="17.0365"
                              y="15.1223"
                              width="8.15546"
                              height="2"
                              rx="1"
                              transform="rotate(45 17.0365 15.1223)"
                              fill="black"
                            ></rect>
                            <path
                              d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                              fill="black"
                            ></path>
                          </svg>
                        </span>
                        <input
                          type="text"
                          v-model="search"
                          :placeholder="
                            $t(
                              'analytics.trending_searches.list.table.search_field'
                            )
                          "
                          class="form-control form-control-solid w-250px ps-14"
                        />
                      </div>
                    </template>

                    <template v-slot:no-data>
                      {{ $t("common.nodata") }}
                    </template>
                  </v-data-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { LOAD_TRENDING_SEARCHES } from "@/core/services/store/search_history.module";
import moment from "moment";
import UsStatsConfiguration from "@/view/content/widgets/USStatsConfiguration.vue";

export default {
  components: {UsStatsConfiguration},
  data() {
    return {
      chartOptions: {
        chart: {
          type: "bar",
          height: 180,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },
          sparkline: {
            enabled: true
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2
        },
        xaxis: {
          type: "datetime"
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          x: {
            show: true,
            format: "dd. MM h:00:00",
            formatter: undefined
          },
          y: {
            formatter: function(val) {
              return parseInt(val).toLocaleString();
            }
          }
        }
      },
      options: {},
      search: "",
      filter: "search",
      headers: [
        { text: "#", value: "row" },
        {
          text: this.$t(
            "analytics.trending_searches.list.table.headers.result_type"
          ),
          value: "result_setting_type_id"
        },
        {
          text: this.$t(
            "analytics.trending_searches.list.table.headers.search_query"
          ),
          value: "term"
        },
        {
          text: this.$t(
            "analytics.trending_searches.list.table.headers.results_avg"
          ),
          value: "results_count"
        },
        {
          text: this.$t(
            "analytics.trending_searches.list.table.headers.phrase_results_avg"
          ),
          value: "phrases_count"
        },
        {
          text: this.$t(
            "analytics.trending_searches.list.table.headers.queries"
          ),
          value: "queries"
        },
        {
          text: this.$t(
            "analytics.trending_searches.list.table.headers.unique_queries"
          ),
          value: "unique_queries"
        },
        {
          text: this.$t(
            "analytics.trending_searches.list.table.headers.fixits"
          ),
          value: "fixits"
        }
      ]
    };
  },
  watch: {
    currentUserConfiguration: {
      handler: function () {
        this.reloadData();

      },
      deep: true,
    },
    changeProject() {
      this.reloadData();
    },
    onDateRangeChanged() {
      this.reloadData();
    }
  },
  computed: {
    changeProject() {
      return this.currentProject.token;
    },
    userConf() {
      return this.currentUserConfiguration.stats;
    },
    graphData() {
      return [
        {
          name: this.$t("analytics.trending_searches.list.timeline.data_title"),
          data: this.trending_searches.timeline.data
        }
      ];
    },
    ...mapState({
      trending_searches: state => state.searchHistory.tables.trending_searches,
      loading: state => state.searchHistory.loading
    }),
    ...mapGetters(["currentProject", "currentUserConfiguration"])
  },
  filters: {
    moment: function(date) {
      return moment(date).format("DD. MM YYYY");
    }
  },
  mounted() {
    this.reloadData();
  },
  methods: {
    reloadData() {
      const data = [];
      if (this.userConf.result_object === "search" || this.userConf.result_object === "all") {
        data.push("result_setting_type_id[]=2");
      }
      if (this.userConf.result_object === "autocomplete" || this.userConf.result_object === "all") {
        data.push("result_setting_type_id[]=1");
      }

      if (this.userConf.date_range.startDate) {
        data.push(
            `start=${moment(this.userConf.date_range.startDate).format("yyyy-MM-DD")}`
        );
      }
      if (this.userConf.date_range.endDate) {
        data.push(`end=${moment(this.userConf.date_range.endDate).format("yyyy-MM-DD")}`);
      }
      if (this.userConf.language) {
        data.push(`language=${this.userConf.language}`);
      }

      this.$store.dispatch(LOAD_TRENDING_SEARCHES, data.join("&"));
    }
  }
};
</script>
