<template>
  <!--begin::Stats Configuration-->
  <ul
      class="nav nav-pills nav-pills-sm nav-dark-75 float-right"
      role="tablist"
  >
    <li class="nav-item">
      <a
          class="nav-link py-2 px-4"
          @click.prevent="toggleResultObject('all')"
          :class="{ active: userConfiguration.stats.result_object === 'all' }"
      >
              <span class="nav-text font-size-sm">{{
                  $t("result_settings.types.all")
                }}</span>
      </a>
    </li>
    <li class="nav-item">
      <a
          class="nav-link py-2 px-4"
          @click.prevent="toggleResultObject('autocomplete')"
          :class="{ active: userConfiguration.stats.result_object === 'autocomplete' }"
      >
              <span class="nav-text font-size-sm">{{
                  $t("result_settings.types.autocomplete")
                }}</span>
      </a>
    </li>
    <li class="nav-item">
      <a
          class="nav-link py-2 px-4"
          @click.prevent="toggleResultObject('search')"
          :class="{ active: userConfiguration.stats.result_object === 'search' }"
      >
              <span class="nav-text font-size-sm">{{
                  $t("result_settings.types.search")
                }}</span>
      </a>
    </li>
    <li class="nav-item" v-if="this.currentProject.languages.length>0">
      <b-form-select v-model="userConfiguration.stats.language"
                     :options="this.currentProject.languages"></b-form-select>
    </li>
    <li class="nav-item">
      <date-range-picker
          ref="picker"
          :locale-data="{
        direction: 'ltr',
        format: 'dd. mm yyyy',
        separator: ' - ',
        applyLabel: 'Apply',
        cancelLabel: 'Cancel',
        weekLabel: 'W',
        customRangeLabel: 'Custom Range',
        daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        monthNames: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec'
        ],
        firstDay: 1
      }"
          single-date-picker="range"
          :min-date="calendar.minDate"
          :max-date="calendar.maxDate"
          :opens="calendar.opens"
          v-model="this.userConfiguration.stats.date_range"
          :ranges="calendar.ranges"
          :control-container-class="calendar.class"
          @update="onDateRangeChanged"
      >
        <template v-slot:input="picker">
          <div class="input-group">
            <div class="input-group-prepend">
            <span class="input-group-text"
            ><i class="fa fa-calendar-alt"></i
            ></span>
            </div>
            <div class="form-control">
              {{ picker.startDate | moment }} - {{ picker.endDate | moment }}
            </div>
          </div>
        </template>
        <div slot="footer" slot-scope="data" class="slot container">
          <b-container class="bv-example-row">
            <b-row>
              <b-col class="text-right">
                <button @click="data.clickCancel" class="btn">Close</button>
                <button
                    v-if="!data.in_selection"
                    @click="data.clickApply"
                    class="btn btn-primary"
                >
                  Apply
                </button>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </date-range-picker>
    </li>
  </ul>
  <!--end::Stats Configuration-->
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import {mapGetters} from "vuex";
import moment from "moment";
import {
  SET_STATS_USER_DATE_RANGE,
  SET_STATS_USER_RESULT_OBJECT,
  SET_STATS_USER_STORE
} from "@/core/services/store/profile.module";

export default {
  name: "usStatsConfiguration",
  components: {DateRangePicker},
  data() {
    return {
      filter: "search",
      picker: {startDate: new Date(), endDate: new Date()},
      calendar: {
        direction: "ltr",
        opens: "left",
        singleDatePicker: true,
        format: "yyyy-mm-dd",
        separator: " - ",
        applyLabel: "Apply",
        cancelLabel: "Cancel",
        weekLabel: "W",
        customRangeLabel: "Custom Range",
        daysOfWeek: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        monthNames: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec"
        ],
        firstDay: 0,
        class: "topbar-item",
        minDate: new Date().setDate(new Date().getDate() - 91),
        maxDate: new Date().setDate(new Date().getDate() - 1),
        ranges: {
          Yesterday: [new Date(), new Date()],
          "Last 3 days": [
            new Date(Date.now() - 3 * 24 * 60 * 60 * 1000),
            new Date(Date.now() - 24 * 60 * 60 * 1000)
          ],
          "Last 7 days": [
            new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
            new Date(Date.now() - 24 * 60 * 60 * 1000)
          ],
          "Last 30 days": [
            new Date(Date.now() - 30 * 24 * 60 * 60 * 1000),
            new Date(Date.now() - 24 * 60 * 60 * 1000)
          ],
          "Last 60 days": [
            new Date(Date.now() - 60 * 24 * 60 * 60 * 1000),
            new Date(Date.now() - 24 * 60 * 60 * 1000)
          ],
          "Last 90 days": [
            new Date(Date.now() - 90 * 24 * 60 * 60 * 1000),
            new Date(Date.now() - 24 * 60 * 60 * 1000)
          ]
        }
      }
    };
  },
  computed: {
    userConfiguration() {
      return this.currentUserConfiguration;
    },
    ...mapGetters(["dateRange", "currentProject", "currentUserConfiguration"])
  },
  filters: {
    moment: function(date) {
      return moment(date).format("DD. MM YYYY");
    }
  },
  watch: {
    userConfiguration: {
      handler: function () {
        this.$store.dispatch(SET_STATS_USER_STORE, this.userConfiguration);
      },
      deep: true,
    }
  },
  methods: {
    moment: function() {
      return moment();
    },
    onDateRangeChanged: function(event) {
      this.$store.dispatch(SET_STATS_USER_DATE_RANGE, {startDate: moment(event.startDate).format(), endDate:moment(event.endDate).format()});
    },
    toggleResultObject(object) {
      this.$store.dispatch(SET_STATS_USER_RESULT_OBJECT, object);
    },
  }
};
</script>
